/**
 * AppointmentState
 */
/* eslint-disable */
import 'moment/locale/es';
import './style.css';

import { AccordionItem, AccordionItemBody, AccordionItemTitle } from 'react-accessible-accordion';
import React, { Component, Fragment, useRef } from 'react';
import Select from 'react-select';

import FormNotify from '../../general/formNotify';
import FormTextArea from '../../general/formTextArea';
import FormTypeHead from '../../general/formTypeHead';
import ListObservations from '../../general/listObservations';
import PropTypes from 'prop-types';
import { CITIES } from '../../../utils/cities';
import DoctorForm from '../../../containers/doctor/doctor';
// import FormInputList from '../../general/formInputList';
import TimeLine from '../../general/timeLine';
import _ from 'lodash';
import assets from '../../../assets';
import moment from 'moment';
import stateAppointment from '../../../utils/appointment/stateAppointment';
import { TILTEST_PHYSICIAN, ESFUERZO_PHYSICIAN, ECO_PHYSICIAN, EKG_PHYSICIAN, STRESS_PHYSICIAN, CANCELATION_APPOINTMENT_OPTIONS, ANULATION_APPOINTMENT_OPTIONS, appointmentStates } from '../../../utils/constants';
import ConfirmationModal from "../../general/confirmationModal";
import { roleHavePermission } from '../../../utils/functions';
import { withRouter } from 'react-router-dom';
import PatientResultForm from '../patientResultForm';
import { context } from '../../../providers/patientProvider';

moment.locale('es');

class AppointmentState extends Component {
  state = {
    listReferringPhysician: [],
    valueReferringPhysician: '',
    selectedReferringPhysician: '',
    idSelectedReferringPhysician: '',
    identicationSelectedReferringPhysician: '',
    openModalRooms: false,
    openModalEditPhysician: false,
    medicoremitente_edit: false,
    savePh: null,
    msPh: '',
    searchDataPh: {},
    repeticionPrestacion: null,
    cancelacionPrestacion: null,
    anulacionPrestacion: null,
    repeticionCausa: '',
    cancelacionCausa: '',
    anulacionCausa: '',
    repeticionOpciones: null,
    cancelacionOpciones: null,
    anulacionOpciones: null,
    repeticionOptionSelected: null,
    cancelacionOptionSelected: null,
    anulacionOptionSelected: null,
    leeValue: null,
    realizaValue: null,
    repeticionSelectRef: null,
    cancelacionSelectRef: null,
    anulacionSelectRef: null,
    repeticionSendDisable: true,
    cancelacionSendDisable: true,
    anulacionSendDisable: true,
    repeticionTextAreaValue: '',
    cancelacionTextAreaValue: '',
    anulacionTextAreaValue: '',
    repeticionMapa: {
      'Sicor': [
        { value: 'Instalación', label: 'Instalación' },
        { value: 'Error en la descarga', label: 'Error en la descarga' },
        { value: 'Pilas', label: 'Pilas' },
        { value: 'Brazalete', label: 'Brazalete' }
      ],
      'Paciente': [
        { value: 'Retira el equipo', label: 'Retira el equipo' }
      ]
    },
    repeticionHolter: {
      'Sicor': [
        { value: 'Instalación', label: 'Instalación' },
        { value: 'Error en la descarga', label: 'Error en la descarga' },
        { value: 'Batería', label: 'Batería' },
        { value: 'Tarjeta', label: 'Tarjeta' }
      ],
      'Paciente': [
        { value: 'Retira el equipo', label: 'Retira el equipo' },
        { value: 'Desconexión de electrodos', label: 'Desconexión de electrodos' }
      ]
    },
    repeticionEkg: {
      'Sicor': [
        { value: 'Cables invertidos', label: 'Cables invertidos' },
        { value: 'Error en el guardado', label: 'Error en el guardado' }
      ],
      'Paciente': [
        { value: 'Interferencia', label: 'Interferencia' }
      ]
    },
    rollbackStateModalConfirmation: false,
  }

  static contextType = context;

  /**
   * config array timeline
   * @param {currentStateAppointment} array list appointments
   */
  componentDidMount = () => {
    this.setState({
      repeticionSelectRef: <Select />
    })
    this.setState({
      cancelacionSelectRef: <Select />
    })
    this.setState({
      anulacionSelectRef: <Select />
    })
  }

  optionsPhysicians = () => {
    const specialities = ['', 'Médica Cardiólogo', 'Médica Endocrinóloga', 'Médica Especialista en Medicina del Deporte', 'Médica General', 'Médica Ginecóloga y Obstetra', 'Médica Internista', 'Medica internista nefrologa ', 'Médica Neuróloga', 'Médico Cardiólogo', 'Médico Cardiólogo y Ecocardiografista', 'Médico Cardiólogo y Electrofisiólogo', 'Médico Deportólogo', 'Médico Endocrinólogo', 'Médico General', 'Médico Internista', 'Médico Neurólogo', 'Médico Neurólogo Vascular'];
    return specialities.map((speciality) => {
      return <option>{speciality}</option>;
    });
  }

  _createArrayTimeLine = (currentStateAppointments) => {
    // const { getPhysicianById } = this.props;
    var _idPhysician;
    var idLeeValue;
    var idRealizaValue;
    var asset = 'radioButtonGray';			/** @param {asset} string type icon [radioButtonGray, radioButtonAqua, radioButtonRed]  */
    var visible = true;									/** @param {visible} boolean visible option for item after cancel or delete  */
    var cancelDeleteTimeLine = null;		/** @param {cancelDeleteTimeLine} object object contain index and data state delete or cancel  */
    var afterLastApointment = null;			/** @param {afterLastApointment} object object contain index and item after last state save appointment  */
    const arrayTimeLine = [];						/** @param {arrayTimeLine} array array with elements for timeLine component  */

    const itemInServiceSala = currentStateAppointments.find(it => it.state === "sala");
    const itemInServiceConsultorio = currentStateAppointments.find(it => it.state === "consultorio");

    if (itemInServiceConsultorio != undefined) {
      if (currentStateAppointments.length >= 5 && itemInServiceSala == undefined) {
        let objSala = {
          "_idPhysician": itemInServiceConsultorio._idPhysician,
          "idLeeValue": itemInServiceConsultorio.leeValue,
          "idRealizaValue": itemInServiceConsultorio.realizaValue,
          "_idAppointments": itemInServiceConsultorio._idAppointments,
          "__typename": itemInServiceConsultorio.__typename,
          "state": "sala",
          "observations": "",
          "date_created": itemInServiceConsultorio.date_created
        }
        currentStateAppointments.splice(4, 0, objSala);
        console.error('state err in SALA');
      }
    }
    //Fix posible duplicates in obj
    currentStateAppointments = [...new Map(currentStateAppointments.map(item => [item['state'], item])).values()];

    stateAppointment.forEach((item, index) => {
      const newItem = Object.assign({}, item);
      let pointClass = '';
      if (cancelDeleteTimeLine) return;
      if (currentStateAppointments[index]) {
        const itemInService = currentStateAppointments[index];
        const { medicoremitente_edit } = this.state;
        if (!medicoremitente_edit) {
          _idPhysician = itemInService._idPhysician;
        }
        idLeeValue = itemInService.leeValue;
        idRealizaValue = itemInService.realizaValue;

        if (!cancelDeleteTimeLine) { // equal null before cancel or delete state

          if (itemInService.state === 'cancelada' || itemInService.state === 'anulada' || itemInService.state === 'reprogramada' || itemInService.state === 'reasignada') {
            cancelDeleteTimeLine = { index, data: itemInService };
            asset = 'radioButtonRed';
            pointClass = 'override';
            newItem.title = itemInService.state;
          }
          else {
            asset = 'radioButtonAqua';
          }
        }
        const nameUser = itemInService._idUser && itemInService._idUser.name ? itemInService._idUser.name + ' ' + itemInService._idUser.lastName : ' ';
        let dateTime = itemInService.date_created ? itemInService.date_created : '';

        let dateTimeLine = '';
        if (dateTime !== '') {
          const arrayPartTime = dateTime.split(' ');
          const formatDateTime = `${arrayPartTime[0]} ${arrayPartTime[2]} ${arrayPartTime[1]} ${arrayPartTime[3]} ${arrayPartTime[4]} -0500`;
          const titleDate = moment(formatDateTime);
          dateTimeLine = titleDate.format('DD[/]MM[/]YYYY[-]hh:mm:ss A');
          dateTime = `${titleDate.format('dddd D [de] MMMM hh:mm:ss A YYYY')}`;
        }
        arrayTimeLine.push({
          asset,
          name: newItem.name,
          title: newItem.title,
          state: itemInService.state,
          footer: (
            <Fragment>
              <div>{nameUser}</div>
              <div>{dateTimeLine}</div>
            </Fragment>
          ),
          visible,
          nameUser,
          dateTime,
          pointClass,
          observations: itemInService.observations === '' ? ' ' : itemInService.observations
        });
        return;
      }

      newItem.visible = visible;
      // save items after last state save and before cancel or override state
      if (!cancelDeleteTimeLine && !afterLastApointment) {
        newItem.pointClass = 'active';
        afterLastApointment = { index, data: newItem };
      }
      // visibility items after cancer or override state
      else if (cancelDeleteTimeLine) {
        newItem.visible = false;
      }
      arrayTimeLine.push(newItem);
    });
    return {
      arrayTimeLine,
      arrayObservations: [...arrayTimeLine].reverse(),
      afterLastApointment,
      cancelDeleteTimeLine,
      _idPhysician,
      idLeeValue,
      idRealizaValue
    };
  }

  SearchPh = async () => {
    const state = this.state;
    const { name, lastname } = state;
    //console.log(name, lastname)
    const value = `${name ? name.trim() : ''} ${lastname ? lastname.trim() : ''}`;
    if (value === '') {
      return
    }
    const { getPhysician } = this.props;
    const listReferringPhysician = await getPhysician(value, true);
    if (!_.isEmpty(listReferringPhysician)) {
      //console.log(listReferringPhysician)
      this.setState({ msPh: `El médico ya está creado en la base de datos.${listReferringPhysician[0].fullname}`, savePh: false, searchDataPh: listReferringPhysician[0] });
    } else {
      this.setState({ msPh: 'El médico no se encuentra en la base de datos.', savePh: true, searchDataPh: {} });
    }

  }

  useThen = () => {
    //console.log("aqui use them")
    const { searchDataPh } = this.state;
    this.setState({ selectedReferringPhysician: searchDataPh.fullname, idSelectedReferringPhysician: searchDataPh._id, openModalRooms: false });
  }

  closeRoomSchedule = () => {
    this.setState({ openModalRooms: false });
  }

  closeModalRefPhEdition = () => {
    this.setState({ openModalEditPhysician: false });
  }

  _onChangeTypeHead = async (options) => {
    if (options.length > 0)
      this.setState({
        selectedReferringPhysician: options[0].fullname,
        idSelectedReferringPhysician: options[0]._id,
        identicationSelectedReferringPhysician: options[0]._id
      });
  }

  clearButton = () => {
    this.setState({ selectedReferringPhysician: '', idSelectedReferringPhysician: '' });

  }

  _onInputChangeTypeHead = async (value) => {
    if (value === '') {
      this.setState({
        selectedReferringPhysician: '',
        idSelectedReferringPhysician: '',
        medicoremitente_edit: true
      });

    } else {
      const { getPhysician } = this.props;

      const listReferringPhysician = await getPhysician(value);

      this.setState({
        listReferringPhysician,
        valueReferringPhysician: value,
        selectedReferringPhysician: '',
        idSelectedReferringPhysician: ''
      });
    }
  }

  _onSaveTypeHead = async () => {

    this.setState({ openModalRooms: true });

  }

  saveMedical = async () => {
    const { savePhysician } = this.props;
    const { name, lastname, specialty, medicalrecord_sicor, email, phone, address } = this.state;
    const fullname = `${name ? name : ''} ${lastname ? lastname : ''}`;
    const status = 'suggested';

    const response = await savePhysician({ name, lastname, specialty, medicalrecord_sicor, email, phone, address, fullname, status });

    const arrayList = this.state.listReferringPhysician;
    arrayList.push({ _id: response._id, fullname });

    if (response._id) {
      this.setState({
        selectedReferringPhysician: fullname,
        idSelectedReferringPhysician: response._id,
        listReferringPhysician: arrayList
      });
      this.closeRoomSchedule();
    }

  }

  updateField = (name, { value }) => {

    this.setState({ [name]: value }, () => {
      this.SearchPh();
    });
  }

  _arrayUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  _triggerRepeticionCausa = e => {
    this.setState({ repeticionOptionSelected: null })
    this.setState({ repeticionSendDisable: true })

    let repeticionPrestacion = ''
    if (this.props.appointmentsService.service && this.props.appointmentsService.service.name.toLowerCase().includes('mapa')) {
      repeticionPrestacion = 'mapa'
    } else if (this.props.appointmentsService.service && this.props.appointmentsService.service.name.toLowerCase().includes('holter')) {
      repeticionPrestacion = 'holter'
    } else if (this.props.appointmentsService.service && this.props.appointmentsService.service.name.toLowerCase().includes('ekg')) {
      repeticionPrestacion = 'ekg'
    }

    const { value } = e.target;
    this.setState({ repeticionCausa: value })
    if (repeticionPrestacion === 'mapa') {
      this.setState({ repeticionOpciones: value === 'Sicor' ? this.state.repeticionMapa.Sicor : this.state.repeticionMapa.Paciente })
      this.setState({
        repeticionSelectRef: ' '
      })
      setTimeout(() => {
        this.setState({
          repeticionSelectRef: <Select
            onChange={this._changeRepeticionSelect}
            options={this.state.repeticionOpciones}
          />
        })
      }, 0);
    } else if (repeticionPrestacion === 'holter') {
      this.setState({ repeticionOpciones: value === 'Sicor' ? this.state.repeticionHolter.Sicor : this.state.repeticionHolter.Paciente })
      this.setState({
        repeticionSelectRef: ' '
      })
      setTimeout(() => {
        this.setState({
          repeticionSelectRef: <Select
            onChange={this._changeRepeticionSelect}
            options={this.state.repeticionOpciones}
          />
        })
      }, 0);
    } else if (repeticionPrestacion === 'ekg') {
      this.setState({ repeticionOpciones: value === 'Sicor' ? this.state.repeticionEkg.Sicor : this.state.repeticionEkg.Paciente })
      this.setState({
        repeticionSelectRef: ' '
      })
      setTimeout(() => {
        this.setState({
          repeticionSelectRef: <Select
            onChange={this._changeRepeticionSelect}
            options={this.state.repeticionOpciones}
          />
        })
      }, 0);
    }
  }

  _triggerCancelacionCausa = e => {
    this.setState({ cancelacionOptionSelected: null })
    this.setState({ cancelacionSendDisable: true })

    const { value } = e.target;
    this.setState({ cancelacionCausa: value })

    this.setState({
      cancelacionOpciones: value === 'Sicor'
        ? CANCELATION_APPOINTMENT_OPTIONS.Sicor
        : CANCELATION_APPOINTMENT_OPTIONS.Paciente
    });

    this.setState({
      cancelacionSelectRef: ' '
    })

    setTimeout(() => {
      this.setState({
        cancelacionSelectRef: <Select
          onChange={this._changeCancelacionSelect}
          options={this.state.cancelacionOpciones}
        />
      })
    }, 0);
  }

  _triggerAnulacionCausa = e => {
    this.setState({ anulacionOptionSelected: null })
    this.setState({ anulacionSendDisable: true })

    const { value } = e.target;
    this.setState({ anulacionCausa: value })

    this.setState({
      anulacionOpciones: value === 'Sicor'
        ? ANULATION_APPOINTMENT_OPTIONS.Sicor
        : ANULATION_APPOINTMENT_OPTIONS.Paciente
    });

    this.setState({
      anulacionSelectRef: ' '
    })

    setTimeout(() => {
      this.setState({
        anulacionSelectRef: <Select
          onChange={this._changeAnulacionSelect}
          options={this.state.anulacionOpciones}
        />
      })
    }, 0);
  }

  _onChangeRepeticionTextAreaValue = e => {
    this.setState({ repeticionTextAreaValue: e.target.value })
  }

  _onChangeCancelacionTextAreaValue = e => {
    this.setState({ cancelacionTextAreaValue: e.target.value })
  }

  _onChangeAnulacionTextAreaValue = e => {
    this.setState({ anulacionTextAreaValue: e.target.value })
  }

  _changeRepeticionSelect = e => {
    this.setState({ repeticionSendDisable: false })
    this.setState({ repeticionOptionSelected: e.value })
  }

  _changeCancelacionSelect = e => {
    this.setState({ cancelacionSendDisable: false })
    this.setState({ cancelacionOptionSelected: e.value })
  }

  _changeAnulacionSelect = e => {
    this.setState({ anulacionSendDisable: false })
    this.setState({ anulacionOptionSelected: e.value })
  }

  _changeLeeSelect = e => {
    this.setState({ leeValue: e.value })
  }

  _changeRevisaSelect = e => {
    this.setState({ realizaValue: e.value })
  }

  _sendRepeticion = () => {

    this.props.saveStateAppointments(
      this.props.appointmentsService,
      'repetir',
      this.state.repeticionCausa + ' | ' + this.state.repeticionOptionSelected + ' | ' + this.state.repeticionTextAreaValue,
      true,
      this.state.idSelectedReferringPhysician,
      undefined,
      this.state.leeValue,
      this.state.realizaValue
    );
  }

  _sendCancelacion = () => {

    this.props.saveStateAppointments(
      this.props.appointmentsService,
      'cancelada',
      this.state.cancelacionCausa + ' | ' + this.state.cancelacionOptionSelected + ' | ' + this.state.cancelacionTextAreaValue,
      true,
      this.state.idSelectedReferringPhysician,
      undefined,
      this.state.leeValue,
      this.state.realizaValue
    );
  }

  _sendAnulacion = () => {

    this.props.saveStateAppointments(
      this.props.appointmentsService,
      'anulada',
      this.state.anulacionCausa + ' | ' + this.state.anulacionOptionSelected + ' | ' + this.state.anulacionTextAreaValue,
      true,
      this.state.idSelectedReferringPhysician,
      undefined,
      this.state.leeValue,
      this.state.realizaValue
    );
  }

  _handleRollbackStateModalConfirmation = state => {
    this.setState({ rollbackStateModalConfirmation: state })
  }

  _handleRollbackStateAppointment = () => {
    this._handleRollbackStateModalConfirmation(false);
    this.props.handleRollbackStateAppointment();
  }

  componentWillReceiveProps = nextProps => {
    const { currentStateAppointments } = nextProps;

    if (!_.isEqual(this.props.currentStateAppointments, currentStateAppointments)) {
      this.setState({
        idSelectedReferringPhysician: '',
        selectedReferringPhysician: '',
        leeValue: null,
        realizaValue: null
      });

      const states = [...currentStateAppointments].reverse();

      const physician = states.find(s => s._idPhysician)?._idPhysician;
      const readerPhysician = states.find(s => s.leeValue)?.leeValue;
      const makerPhysician = states.find(s => s.realizaValue)?.realizaValue;

      if (physician) {
        this.setState({
          idSelectedReferringPhysician: physician._id,
          selectedReferringPhysician: physician.fullname
        });
      }

      if (readerPhysician) {
        this.setState({ leeValue: readerPhysician._id });
      }

      if (makerPhysician) {
        this.setState({ realizaValue: makerPhysician._id })
      }
    }
  }

  canSelectPhysicians = () => {
    const stateAppointments = this.props.currentStateAppointments;
    const lastState = stateAppointments[stateAppointments.length - 1]?.state;

    return (
      lastState &&
      lastState !== 'repetir' &&
      lastState !== 'anulada' &&
      lastState !== 'cancelada' &&
      lastState !== 'reprogramada' &&
      lastState !== 'reasignada'
    );
  }

  assignReferringPhysician = async physician => {
    await this._onInputChangeTypeHead(physician.fullname);
    await this._onChangeTypeHead(this.state.listReferringPhysician);
  }

  handleSucccessSavePhysician = async physician => {
    this.assignReferringPhysician(physician);
    this.closeRoomSchedule();
  }

  handleSucccessUpdatePhysician = async physician => {
    this.assignReferringPhysician(physician);
    this.closeModalRefPhEdition();
  }

  render() {
    const { listReferringPhysician, rollbackStateModalConfirmation } = this.state;

    let { selectedReferringPhysician, idSelectedReferringPhysician, leeValue, realizaValue } = this.state;
    const {
      isModal,
      onCloseNotify,
      // currentAppointments,
      // medicoremitente_edit,
      valueObservation,
      appointmentsService,
      onChangeObservation,
      saveStateAppointments,
      toggleVisibleModalNull,
      currentStateAppointments,
      user,
      handleVisibilityResultForm,
      showPatientResultForm
    } = this.props;

    const { openModalRooms, savePh, msPh } = this.state;
    if (!appointmentsService._id) return <div></div>; // not selected appointment service
    let assetIconAdd = assets.plusBlue;
    let classReferring = 'green';

    if (idSelectedReferringPhysician !== '') {
      assetIconAdd = assets.plusGrey;
      classReferring = 'gray';
    }
    const { arrayTimeLine, arrayObservations, afterLastApointment, cancelDeleteTimeLine } = this._createArrayTimeLine(currentStateAppointments);
    const service = appointmentsService.service ? appointmentsService.service : {};
    let titleDate = '';

    if (appointmentsService.time) {
      titleDate = moment(parseInt(appointmentsService.time, 10)).format('dddd D [de] MMMM [/] hh:mm:ss A YYYY');
    }

    const inputReferringPhysician =
      <div>
        <div className="referring-physician-input">
          <FormTypeHead
            id="typehead_physician"
            name="fullname"
            label="Médico remitente"
            labelKey="fullname"
            aditional="identificacion"
            renderAtional={true}
            options={listReferringPhysician}
            multiple={!1}
            clearButton={this.clearButton}
            onChange={this._onChangeTypeHead}
            onInputChange={this._onInputChangeTypeHead}
            selectedOptions={selectedReferringPhysician !== '' ?
              [{
                fullname: selectedReferringPhysician,
                id_: idSelectedReferringPhysician
              }]
              : []
            }
          />
        </div>

        {
          this.state.identicationSelectedReferringPhysician != '' ?
            <div>
              <button
                type="button"
                class="btn btn-success btn-sm ml-2"
                onClick={() => this.setState({ openModalEditPhysician: true })}
              >
                Editar Médico
              </button>
            </div>
            :
            <button
              type="button"
              class="btn btn-success btn-sm ml-2"
              onClick={idSelectedReferringPhysician === '' ? this._onSaveTypeHead : () => { }}
            >
              Nuevo Médico
            </button>
        }

      </div>;

    const trimObservation = valueObservation.trim();

    const todayDate = new Date(Date.now())
    //tomorrow
    //todayDate.setDate(todayDate.getDate() + 1)
    todayDate.setHours(0, 0, 0, 0)

    const appointmentDate = new Date(moment(parseInt(appointmentsService.time, 10)))
    appointmentDate.setHours(0, 0, 0, 0)
    //isTheDate returns true if today is the same date as  the appointment's date and false if it nos the same date (and current state is present)
    const isTheDate = (
      (todayDate.getTime() === appointmentDate.getTime()) ?
        true
        : (
          (afterLastApointment && afterLastApointment.data.name === 'presente') ? false : true)
    )

    const alertaDiaYaPaso = (
      (todayDate.getTime() > appointmentDate.getTime()) ?
        <span className="error-text">Error: esta cita expiró. La cita estaba agendada para el día {appointmentDate.getDate()}/{appointmentDate.getMonth()}/{appointmentDate.getFullYear()}</span>
        : null
    )

    const body =
      <div className="appoinment-state">

        { /* TimeLine component */}
        <TimeLine
          name="user-state-appointment"
          steps={arrayTimeLine}
          defaultFooter={(
            <Fragment>
              <div>{'\u00A0'}</div>
              <div>{'\u00A0'}</div>
            </Fragment>
          )}
        />
        {openModalRooms &&
          <Fragment>
            <div className='modal bd-example-modal-lg' tabIndex='-1' role='dialog' style={{ display: openModalRooms ? 'block' : 'none' }}>
              <div className='modal-dialog modal-lg' role='document'>
                <div className='modal-content'>
                  <div className="modal-header">
                    <h5 className="modal-title">Nuevo Médico Remitente</h5>
                  </div>
                  <div className='modal-bodyr' style={{ padding: '20px 30px' }}>
                    <DoctorForm
                      onSuccess={this.handleSucccessSavePhysician}
                    />
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={this.closeRoomSchedule}
                      data-dismiss='modal'
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        }
        {
          this.state.openModalEditPhysician &&
          <Fragment>
            <div className='modal bd-example-modal-lg' tabIndex='-1' role='dialog' style={{ display: this.state.openModalEditPhysician ? 'block' : 'none' }}>
              <div className='modal-dialog modal-lg' role='document'>
                <div className='modal-content'>
                  <div className="modal-header">
                    <h5 className="modal-title">Editar Médico Remitente</h5>
                  </div>
                  <div className='modal-bodyr' style={{ padding: '20px 30px' }}>
                    <DoctorForm
                      identity={this.state.identicationSelectedReferringPhysician}
                      onSuccess={this.handleSucccessUpdatePhysician}
                    />
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={this.handleSucccessUpdatePhysician}
                      data-dismiss='modal'>
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        }

        {
          rollbackStateModalConfirmation &&
          <ConfirmationModal
            title='Estas seguro que deseas que la cita pase a un estado previo?'
            subititle='Es irreversible'
            onConfirm={() => this._handleRollbackStateAppointment()}
            onCancel={() => this._handleRollbackStateModalConfirmation(false)}
          />
        }

        {
          currentStateAppointments[currentStateAppointments.length - 1] &&
          (currentStateAppointments[currentStateAppointments.length - 1].state === 'agendada' ||
            currentStateAppointments[currentStateAppointments.length - 1].state === 'confirmada') &&
          <div className='repetir-container'>
            <div className='repetir-title'>Cancelar cita</div>
            <div className='repetir-sicor'>
              <input
                type="radio"
                name="cancelacionCausa"
                value="Sicor"
                onChange={this._triggerCancelacionCausa}
              />Sicor
            </div>
            <div className='repetir-paciente'>
              <input
                type="radio"
                name="cancelacionCausa"
                value="Paciente"
                onChange={this._triggerCancelacionCausa}
              />Paciente
            </div>
            <div className='repetir-list'>
              {this.state.cancelacionSelectRef}
            </div>
            <div className='repetir-enviar'>
              <button
                type="buttom"
                className={`button ripple save-user button-sm bg-icon`}
                onClick={this._sendCancelacion}
                disabled={this.state.cancelacionSendDisable}
              >
                Enviar
              </button>
            </div>
            <div className='repetir-comentarios'>
              <textarea
                name="observation-repetir"
                className='font-14'
                placeholder="Observaciones"
                value={this.state.cancelacionTextAreaValue}
                onChange={this._onChangeCancelacionTextAreaValue}
              />
            </div>
          </div>
        }
        {
          currentStateAppointments[currentStateAppointments.length - 1] &&
          (currentStateAppointments[currentStateAppointments.length - 1].state === 'presente' ||
            currentStateAppointments[currentStateAppointments.length - 1].state === 'recepcion' ||
            currentStateAppointments[currentStateAppointments.length - 1].state === 'sala' ||
            currentStateAppointments[currentStateAppointments.length - 1].state === 'consultorio') &&
          <div className='repetir-container'>
            <div className='repetir-title'>Anular cita</div>
            <div className='repetir-sicor'>
              <input
                type="radio"
                name="anulacionCausa"
                value="Sicor"
                onChange={this._triggerAnulacionCausa}
              />Sicor
            </div>
            <div className='repetir-paciente'>
              <input
                type="radio"
                name="anulacionCausa"
                value="Paciente"
                onChange={this._triggerAnulacionCausa}
              />Paciente
            </div>
            <div className='repetir-list'>
              {this.state.anulacionSelectRef}
            </div>
            <div className='repetir-enviar'>
              <button
                type="buttom"
                className={`button ripple save-user button-sm bg-icon`}
                onClick={this._sendAnulacion}
                disabled={this.state.anulacionSendDisable}
              >
                Enviar
              </button>
            </div>
            <div className='repetir-comentarios'>
              <textarea
                name="observation-repetir"
                className='font-14'
                placeholder="Observaciones"
                value={this.state.anulacionTextAreaValue}
                onChange={this._onChangeAnulacionTextAreaValue}
              />
            </div>
          </div>
        }
        {
          this.props.appointmentsService.service &&
          (this.props.appointmentsService.service.name.toLowerCase().includes('mapa') || this.props.appointmentsService.service.name.toLowerCase().includes('holter') || this.props.appointmentsService.service.name.toLowerCase().includes('ekg')) &&
          currentStateAppointments[currentStateAppointments.length - 1] &&
          currentStateAppointments[currentStateAppointments.length - 1].state === 'atencion' &&
          <div className='repetir-container'>
            <div className='repetir-title'>Repetir cita</div>
            <div className='repetir-sicor'>
              <input
                type="radio"
                name="repeticionCausa"
                value="Sicor"
                onChange={this._triggerRepeticionCausa}
              />Sicor
            </div>
            <div className='repetir-paciente'>
              <input
                type="radio"
                name="repeticionCausa"
                value="Paciente"
                onChange={this._triggerRepeticionCausa}
              />Paciente
            </div>
            <div className='repetir-list'>
              {this.state.repeticionSelectRef}
            </div>
            <div className='repetir-enviar'>
              <button
                type="buttom"
                className={`button ripple save-user button-sm bg-icon`}
                onClick={this._sendRepeticion}
                disabled={this.state.repeticionSendDisable}
              >
                Enviar
              </button>
            </div>
            <div className='repetir-comentarios'>
              <textarea
                name="observation-repetir"
                className='font-14'
                placeholder="Observaciones"
                value={this.state.repeticionTextAreaValue}
                onChange={this._onChangeRepeticionTextAreaValue}
              />
            </div>
          </div>
        }
        {
          currentStateAppointments[currentStateAppointments.length - 1] &&
          currentStateAppointments[currentStateAppointments.length - 1].state !== 'repetir' &&
          // currentStateAppointments[currentStateAppointments.length - 1].state !== 'entregado' &&
          /* referring physician */
          <div className="referring-physician">
            {inputReferringPhysician}
          </div>
        }
        {
          this.canSelectPhysicians() &&
          this.props.appointmentsService.service.name == 'Tilt Test' &&
          <div className="extra-physician">
            <span>Médico que realiza</span>
            <Select
              defaultValue={
                TILTEST_PHYSICIAN.realiza.filter(option =>
                  option.value == realizaValue)
              }
              onChange={this._changeRevisaSelect}
              options={TILTEST_PHYSICIAN.realiza}
            />
            <span>Médico que lee</span>
            <Select
              defaultValue={
                TILTEST_PHYSICIAN.lee.filter(option =>
                  option.value == leeValue)
              }
              onChange={this._changeLeeSelect}
              options={TILTEST_PHYSICIAN.lee}
            />
          </div>
        }
        {
          this.canSelectPhysicians() &&
          (this.props.appointmentsService.service.name.toLowerCase().indexOf('prueba de esfuerzo') >= 0
            || this.props.appointmentsService.service.name.toLowerCase().indexOf('pdee') >= 0) &&
          <div className="extra-physician">
            <span>Médico que realiza</span>
            <Select
              defaultValue={
                ESFUERZO_PHYSICIAN.realiza.filter(option =>
                  option.value == realizaValue)
              }
              onChange={this._changeRevisaSelect}
              options={ESFUERZO_PHYSICIAN.realiza}
            />
            <span>Médico que lee</span>
            <Select
              defaultValue={
                ESFUERZO_PHYSICIAN.lee.filter(option =>
                  option.value == leeValue)
              }
              onChange={this._changeLeeSelect}
              options={ESFUERZO_PHYSICIAN.lee}
            />
          </div>
        }
        {
          this.canSelectPhysicians() &&
          (this.props.appointmentsService.service.name.toLowerCase().indexOf('eco doppler') >= 0
            || this.props.appointmentsService.service.name.toLowerCase().indexOf('eco tt') >= 0) &&
          <div className="extra-physician">
            <span>Médico que realiza</span>
            <Select
              defaultValue={
                ECO_PHYSICIAN.realiza.filter(option =>
                  option.value == realizaValue)
              }
              onChange={this._changeRevisaSelect}
              options={ECO_PHYSICIAN.realiza}
            />
            <span>Médico que lee</span>
            <Select
              defaultValue={
                ECO_PHYSICIAN.lee.filter(option =>
                  option.value == leeValue)
              }
              onChange={this._changeLeeSelect}
              options={ECO_PHYSICIAN.lee}
            />
          </div>
        }

        {
          this.canSelectPhysicians() &&
          this.props.appointmentsService.service.name.toLowerCase() === 'ekg' &&
          <div className="extra-physician">
            <span>Médico que realiza</span>
            <Select
              defaultValue={
                EKG_PHYSICIAN.realiza.filter(option =>
                  option.value == realizaValue)
              }
              onChange={this._changeRevisaSelect}
              options={EKG_PHYSICIAN.realiza}
            />
            <span>Médico que lee</span>
            <Select
              defaultValue={
                EKG_PHYSICIAN.lee.filter(option =>
                  option.value == leeValue)
              }
              onChange={this._changeLeeSelect}
              options={EKG_PHYSICIAN.lee}
            />
          </div>
        }

        {
          this.canSelectPhysicians() &&
          this.props.appointmentsService.service.name.toLowerCase().indexOf('stress') >= 0 &&
          <div className="extra-physician">
            <span>Médico que realiza</span>
            <Select
              defaultValue={
                STRESS_PHYSICIAN.realiza.filter(option =>
                  option.value == realizaValue)
              }
              onChange={this._changeRevisaSelect}
              options={STRESS_PHYSICIAN.realiza}
            />
            <span>Médico que lee</span>
            <Select
              defaultValue={
                STRESS_PHYSICIAN.lee.filter(option =>
                  option.value == leeValue)
              }
              onChange={this._changeLeeSelect}
              options={STRESS_PHYSICIAN.lee}
            />
          </div>
        }

        {
          currentStateAppointments[currentStateAppointments.length - 1] &&
          currentStateAppointments[currentStateAppointments.length - 1].state !== 'repetir' &&
          currentStateAppointments[currentStateAppointments.length - 1].state !== 'entregado' &&
          /* observation input */
          !cancelDeleteTimeLine && afterLastApointment &&
          <div className="fielt-state-active">
            <FormTextArea
              label={`Observaciones ${afterLastApointment.data && afterLastApointment.data.title == 'Repetir' ? `Entregado` : `${afterLastApointment.data.title}`}`}
              name="observation-timeline"
              value={valueObservation}
              onChange={onChangeObservation}
            />
            <div className="flex f-c a-i-c j-c mt-1">

              {afterLastApointment.data.name === 'presente' ? alertaDiaYaPaso : null}
              <div className="text-center flex j-c">
                <button
                  type="buttom"
                  className={isTheDate ? "button ripple save-user button-sm bg-icon" : "button ripple save-user button-sm  btn-muted ripple-btn-gray"}
                  disabled={!isTheDate}
                  onClick={() => {
                    saveStateAppointments(
                      appointmentsService,
                      afterLastApointment.data.name != 'repetir' ? afterLastApointment.data.name : 'entregado',
                      valueObservation,
                      true,
                      idSelectedReferringPhysician,
                      undefined,
                      this.state.leeValue,
                      this.state.realizaValue
                    );
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        }

        { /* List observation */}
        <ListObservations list={arrayObservations} title="Observaciones" />
      </div >;

    return (
      <>
        <AccordionItem uuid="uniqueItem-state" expanded={true}>
          <AccordionItemTitle
            className="accordion__title accordion__title--animated u-position-relative ripple-title"
            onClick={() => { }}
          >
            <span>
              {service.name}
              <span style={{ fontSize: 11 }}> ({titleDate})</span>
            </span>

            <div className='ms-auto'>
              {user._role && roleHavePermission(user, "devolverEstado") &&
                !_.isEmpty(currentStateAppointments) &&
                currentStateAppointments[currentStateAppointments.length - 1]?.state !== appointmentStates.scheduled &&
                (
                  <button
                    type="button"
                    className="btn btn-warning me-3"
                    onClick={() => this._handleRollbackStateModalConfirmation(true)}
                  >
                    Devolver estado
                  </button>
                )
              }

              {!_.isEmpty(currentStateAppointments) &&
                currentStateAppointments[currentStateAppointments.length - 1]?.state === appointmentStates.attention &&
                (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => handleVisibilityResultForm()}
                  >
                    Registrar resultado
                  </button>
                )
              }

              {_.isNull(this.props?.appointmentsService?.billed) && (
                <button
                  type="button"
                  className="btn btn-info me-3"
                  onClick={() => this.context.handleVisibilityBillingForm()}
                >
                  Formulario Facturación
                </button>
              )}
            </div>

          </AccordionItemTitle>
          <AccordionItemBody>{body}</AccordionItemBody>
        </AccordionItem>


        {showPatientResultForm &&
          <PatientResultForm
            handleVisibilityResultForm={handleVisibilityResultForm}
            selectedReferringPhysician={selectedReferringPhysician}
            idSelectedReferringPhysician={idSelectedReferringPhysician}
            readerPhysician={leeValue}
            makerPhysician={realizaValue}
          />
        }
      </>
    );
  }
}

AppointmentState.propTypes = {
  isModal: PropTypes.bool,
  onCloseNotify: PropTypes.func,
  // currentAppointments: PropTypes.array,
  valueObservation: PropTypes.string,
  appointmentsService: PropTypes.object,
  onChangeObservation: PropTypes.func,
  saveStateAppointments: PropTypes.func,
  getPhysician: PropTypes.func,
  getPhysicianById: PropTypes.func,
  toggleVisibleModalNull: PropTypes.func,
  saveReferringPhysician: PropTypes.func,
  savePhysician: PropTypes.func,
  currentStateAppointments: PropTypes.array
};

export default withRouter(AppointmentState);
